import React from "react";

const QuestionMarkSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.58" height="15.656" viewBox="0 0 9.58 15.656">
      <path id="Path_14308" data-name="Path 14308"
            d="M3.42-3.175a2.6,2.6,0,0,0,.819,1.934l2-.66a1.43,1.43,0,0,1-.5-1.024c0-.91.8-1.456,1.661-2.093,1.138-.8,2.412-1.707,2.412-3.6,0-2.048-1.752-3.573-4.619-3.573A6.3,6.3,0,0,0,.234-9.933L1.759-8.227A3.91,3.91,0,0,1,4.922-9.819c1.479,0,2.162.751,2.162,1.638s-.751,1.411-1.593,2C4.512-5.473,3.42-4.7,3.42-3.175Zm.046,5.052a1.593,1.593,0,1,0,3.186,0,1.593,1.593,0,1,0-3.186,0Z"
            transform="translate(-0.234 12.186)" />
    </svg>
  );
};

export default QuestionMarkSvg;
