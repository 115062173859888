import React from "react";

const Notification = ({notificationCloseClass}) => {
  return (
    <svg
      className={notificationCloseClass}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Chevron Down"
      role="img"
    >
      <title>Chevron Down</title>
      <path
        d="M11.9954 15.0785L19.5387 7.54001L21 9.00226L11.9945 18.002L3 9.00179L4.46225 7.54047L11.9954 15.0785Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default Notification;
