import React, { useState } from "react";
import IconNotofication from "../svg/Notification";
const Notification = ({isOpenNotification, toggleNotification}) => {
  const [isMenuOpenDesktop, setIsMenuOpenDesktop] = useState(true);

  const notificationPhantomClass = isOpenNotification
    ? "rb-notification-phantom open"
    : "rb-notification-phantom";

  const notificationBlockClass = isOpenNotification
    ? "rb-notification-block open"
    : "rb-notification-block";

  const notificationCloseClass = isOpenNotification
    ? "rb-notification-close-svg open"
    : "rb-notification-close-svg";
  return (
    <div className="rb-notification">
      <div className={notificationPhantomClass} />
      <div className={notificationBlockClass}>
        <div className="rb-notification-text">
          <p>Get your FREE vBase account and start making stamps! </p>

          <div className="rb-notification-buttons rb-display-flex rb-justify-content-space-between">
            <a
              href="/accounts/login/"
              className="rb-btn rb-btn-black rb-notification-buttons-item"
            >
              Sign In
            </a>

            <a
              href="/accounts/signup/"
              className="rb-btn rb-btn-black rb-notification-buttons-item"
            >
              Register
            </a>
          </div>
        </div>
        <a
          href="#"
          onClick={toggleNotification}
          className="rb-notification-close"
        >
          <IconNotofication notificationCloseClass={notificationCloseClass} />
        </a>
      </div>
    </div>
  );
};

export default Notification;
