export const updateHashWorker = async (hash, created = false, setIsLoading, setError, setStampData, setCurrentScreen, setButton, setLoading) => {
    try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("object-hash", hash);
        formData.append("filter-by-user", true);
        const csrfToken = document.querySelector(
            "[name=csrfmiddlewaretoken]",
        ).value;
        fetch("/verify/hash/", {
            method: "POST",
            headers: {
                "X-CSRFToken": csrfToken,
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.stamp_list) {

                    setError(null);
                    setStampData(data.stamp_list[0]);
                    if (created) {

                        setCurrentScreen("created");
                    } else {
                        const userStampsFound = data.stamp_list && data.stamp_list.length > 0
                        if (userStampsFound) {
                            setCurrentScreen("found");
                        } else {
                            setButton(true);
                        }
                    }
                } else {
                    setButton(true);
                    setCurrentScreen("");
                    if (data.message === "Invalid Content ID.") {
                        setButton(true);
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setError("An error occurred while verifying the stamp.");
                setLoading(false);
                setButton(true);
            });
    } catch (error) {
        console.error("Error in API request:", error);
        setButton(true);
    } finally {
        setIsLoading(false);
    }
};
