import Web3 from 'web3';

export const processFile = (file, setObjectHash, handleUpdateHashWorker) => {
    const reader = new FileReader();

    reader.onload = function () {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);

        const hexStringArray = byteArray.reduce((acc, byte) => {
            return acc + ("00" + byte.toString(16)).slice(-2);
        }, "");

        const web3 = new Web3();
        const hash = web3.utils.soliditySha3({
            type: "bytes",
            value: hexStringArray,
        });
        setObjectHash(hash);
        handleUpdateHashWorker(hash);
    };

    reader.readAsArrayBuffer(file);
};
