export const updateHashWorkerVerify = async (
    hash,
    setError,
    setStampData,
    setStampList,
    setCurrentScreen,
    setLoading,
    setObjectHash
) => {
    const url = new URL(window.location);
    url.searchParams.set("cid", hash);
    window.history.pushState({}, "", url);

    const formData = new FormData();
    formData.append("object-hash", hash);

    const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;

    try {
        const response = await fetch("/verify/hash/", {
            method: "POST",
            headers: {
                "X-CSRFToken": csrfToken,
            },
            body: formData,
        });
        const data = await response.json();

        if (data.stamp) {
            data.stamp_list = data.stamp_list.slice(0, 1);
            setStampData(data.stamp_list[0]);
            setStampList(data.stamp_list);
            setCurrentScreen("found");
            setError(null);
        } else {
            setStampData(null);
            setStampList(null);
            setObjectHash(hash);
            setCurrentScreen("notfound");
            setError(null);
        }
        setLoading(false);
    } catch (error) {
        console.error("Error:", error);
        setError("An error occurred while verifying the stamp.");
        setLoading(false);
    }
};
