import React from "react";
import { createRoot } from "react-dom/client";
import StampForm from "./pages/StampForm";
import VerifyForm from "./pages/VerifyForm";
import Header from "./components/Header";
import Footer from "./components/Footer";

const props = window.props || {};
const staticBasePath = "/static/";

const stampRootMount = document.getElementById("stamp-root");
const verifyRootMount = document.getElementById("verify-root");
const headerRootMount = document.getElementById("header-root");
const footerRootMount = document.getElementById("footer-root");
function getValue(key) {
  const element = document.getElementById(key);
  if (element) {
    // Clean up the value by trimming and removing surrounding quotes
    let cleanedText = element.textContent.trim();
    if (cleanedText.startsWith('"') && cleanedText.endsWith('"')) {
      cleanedText = cleanedText.slice(1, -1);
    }
    return cleanedText;
  }
  return '';
}
function getValueAsBoolean(key) {
  const element = document.getElementById(key);
  if (element) {
    const trimmedText = element.textContent.trim().toLowerCase();
    return trimmedText === 'true';
  }
  return false;
}

const userIsAuthenticated = getValueAsBoolean("user-is-authenticated") || false
const userEmail = getValue("user-email")
const userShortName = getValue("user-short-name")
const userNameValue = getValue("user-name")

const djangoContext = {
  userIsAuthenticated,
  userEmail,
  userShortName,
  userNameValue,
};

if (stampRootMount) {
  const root = createRoot(stampRootMount);
  root.render(
      <StampForm
          {...props}
          staticBasePath={staticBasePath}
          djangoContext={djangoContext}
      />,
  );
}

if (headerRootMount) {
  const root = createRoot(headerRootMount);
  root.render(
      <Header
          {...props}
          staticBasePath={staticBasePath}
          djangoContext={djangoContext}
      />,
  );
}
if (footerRootMount) {
  const root = createRoot(footerRootMount);
  root.render(<Footer {...props} staticBasePath={staticBasePath} />);
}

if (verifyRootMount) {
  const root = createRoot(verifyRootMount);
  root.render(<VerifyForm {...props} staticBasePath={staticBasePath} djangoContext={djangoContext} />);
}
