import React from "react";
import {motion} from "framer-motion";

const Loading = () => {

    return (
        <>
            <div className="rb-loading-bg"></div>
            <div className="rb-loading-wrap">
                <div className="rb-loading__loader">
                    <motion.span
                        animate={{
                            rotate: 360,
                        }}
                        transition={{
                            repeat: Infinity,
                            duration: 1,
                            ease: "linear",
                        }}
                    />
                </div>
                <div className="rb-loading__title">
                    One moment! Stamping in progress...
                </div>
                <div className="rb-loading__text">
                    Can take 20 seconds, but usually faster!
                </div>
            </div>
        </>
    );
};

export default Loading;
