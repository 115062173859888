import React, { useState } from "react";
import sanitizeHtml from 'sanitize-html';
const Help = ({position, text, link}) => {
  const [isActiveQuestion, setIsActiveQuestion] = useState(false);

  const handleMouseEnterQuestion = () => {
    setIsActiveQuestion(true);
  };

  const handleMouseLeaveQuestion = () => {
    setIsActiveQuestion(false);
  };
  const sanitizedText = text && sanitizeHtml(text.__html, {
    allowedTags: ['b', 'p'],
    allowedAttributes: {}
  });
  return (
    <div
         className={`help__question ${isActiveQuestion ? "active" : ""} ${position}`}
         onMouseEnter={handleMouseEnterQuestion}
         onMouseLeave={handleMouseLeaveQuestion}
    >
      <div className="help__question-mark">
        <span>?</span>
      </div>

        <div className="help__question-answer">

          {sanitizedText ? (
            <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />
          ) : (
            <p>{text}</p>
          )}
          {link && (
          <a target="_blank" href={link}>Learn more</a>
          )}
        </div>

    </div>
  );
};

export default Help;
