import React from "react";
import Info from "./Info";

const StampContentId = ({
                            currentScreen,
                            staticBasePath,
                            handleRemoveHash,
                            isSmallScreen,
                            isAuthenticated,
                            isValid,
                            objectHash,
                            handleHashChange,
                            handleCreateSubmit,
                            isDisabled,
                          buttonStampClassName
                        }) => {

    return (
        <>
            <div
                id="verify-method-hash__screen"
                className="rb-method-hash__screen"
            >
                <div className="rb-method">
                    {currentScreen === "found" && (
                        <div className="rb-container">
                            <Info
                                textInfo="You have already stamped this file previously. Please choose another one."
                                staticBasePath={staticBasePath}
                            />
                        </div>
                    )}
                    <div className="rb-container rb-hash-text-wrap">
                        <div className="rb-hash-input">
                            <input
                                type="text"
                                id="object-hash"
                                className={`rb-btn rb-btn-idle rb-input ${isValid ? "" : "invalid"}`}
                                placeholder="Enter content ID"
                                pattern="^(0x)?[0-9a-fA-F]{64}$"
                                value={objectHash}
                                onChange={handleHashChange}
                            />
                            <img
                                onClick={handleRemoveHash}
                                src={`${staticBasePath}public/images/svg/rb-remove-hash.svg`}
                                alt="Remove Hash"
                            />
                        </div>
                        {!isValid && (
                            <div className="rb-invalid-text">
                                Invalid content ID format.
                            </div>
                        )}

                        {isSmallScreen && (
                            <div className="rb-info rb-btn-stamp-small">
                                <button
                                    className={buttonStampClassName}
                                    onClick={handleCreateSubmit}
                                    disabled={isDisabled}
                                >
                                    Make a vBase Stamp!
                                </button>
                                {(!isAuthenticated) && (
                                    <div
                                        className="rb-stamper-login rb-display-flex rb-justify-content-center w-100">
                                        <a href="/accounts/login/">Sign In</a> to Start Stamping!
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="rb-container">
                <div
                    className="rb-method-hash-text-wrap rb-method-hash-text-wrap-first rb-display-flex rb-align-items-center">
                    <img
                        src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
                        alt="Info"
                    />
                    <div className="rb-method-hash-text">
                        <span>This is an advanced feature and</span> not recommended
                        for typical users.
                    </div>
                </div>
                <div className="rb-method-hash-text-wrap rb-display-flex rb-align-items-center">
                    <img
                        src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
                        alt="Info"
                    />
                    <div className="rb-method-hash-text">
                  <span>
                    Please be sure that your content ID matches a file you saved
                    locally.
                  </span>
                    </div>
                </div>

                <div className="rb-method-hash-text-wrap rb-display-flex rb-align-items-center">
                    <img
                        src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
                        alt="Info"
                    />
                    <div className="rb-method-hash-text">
                        If this process fails, your content ID will not be validated.
                    </div>
                </div>

                <div
                    className="rb-method-hash-text-wrap rb-method-hash-text-wrap-last rb-display-flex rb-align-items-center">
                    <img
                        src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
                        alt="Info"
                    />
                    <div className="rb-method-hash-text">
                        If you need help creating a robust workflow to stamp content
                        IDs, <a href="mailto:admin@admin.com">please contact us</a>.
                    </div>
                </div>
            </div>
        </>
    );
};

export default StampContentId;
