export const createSubmit = async (objectHash, setLoading, setError, handleUpdateHashWorker) => {
    setLoading(true);
    const csrfToken = document.querySelector(
        "[name=csrfmiddlewaretoken]",
    ).value;
    fetch("/stamp/create-stamp/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ "object-hash": objectHash }),
    })
        .then((response) => response.json())
        .then((data) => {

            if (data.error) {
                setError(data);
            } else {
                handleUpdateHashWorker(objectHash, true);
              console.log(data);
            }
        })
        .catch((error) => {
            setLoading(false);
            setError(`Error: ${error.message}`);
        });
};
