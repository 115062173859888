import React from "react";

const Footer = () => {
  return (
    <footer className="rb-footer">
      <p>
        © 2024 validityBase. All rights reserved.{" "}
        <span className="rb-footer-links">
          <a target="_blank" href="https://www.vbase.com/terms-of-service/">Terms of Service</a>
        <span className="rb-footer-links-text">and</span>
          <span className="rb-footer-links-text-mobile">|</span>
        <a target="_blank" href="https://vbase.com/privacy-policy/">Privacy Policy</a>
          </span>
      </p>
    </footer>
  );
};
export default Footer;
