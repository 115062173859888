import React from "react";
import HelpTriggerSvg from "../svg/HelpTriggerSvg";
const TriggerHelp = ({helpIsActive, isNearBottom, isAuthenticated, isOpenNotification, handleHelpTriggerClick}) => {
  return (
    <div className={`help__trigger
  ${helpIsActive ? "active" : ""}
  ${isNearBottom ? "near-bottom" : ""}
  ${!isAuthenticated ? (isOpenNotification ? "not-authenticated-open" : "not-authenticated") : ""}`}>
      <div className="help__trigger-text">
        Help <span>Off / On</span>
      </div>
      <HelpTriggerSvg handleHelpTriggerClick={handleHelpTriggerClick}/>
    </div>
  );
};

export default TriggerHelp;
