import React, {useState, useCallback, useEffect} from "react";
import Cookies from 'js-cookie';
import debounce from "lodash/debounce";
import Loading from "../components/Loading";
import StampError from "../components/StampError";
import StampCreated from "../components/StampCreated";
import IconStampFromFile from "../svg/StampFromFile";
import IconStampContentId from "../svg/StampContentId";
import Header from "../components/Header";
import Notification from "../components/Notification";
import Footer from "../components/Footer";
import StampContentId from "../components/StampContentId";
import StampFromFile from "../components/StampFromFile";
import {processFile} from "../utils/processFile";
import {createSubmit} from "../utils/createSubmit";
import {updateHashWorker} from "../utils/updateHashWorker";
import {pingForwarder} from "../utils/pingForwarder";
import TriggerHelp from "../components/TriggerHelp";
import NeedHelp from "../components/NeedHelp";
import Help from "../components/Help";
const StampForm = ({initialHash = "", staticBasePath, djangoContext}) => {

    const [helpIsActive, setHelpIsActive] = useState(() => {
      const cookieValue = Cookies.get('helpIsActive');
      return cookieValue !== undefined ? JSON.parse(cookieValue) : true;
    });

    const handleHelpTriggerClick = () => {
      const newHelpIsActive = !helpIsActive;
      setHelpIsActive(newHelpIsActive);
      Cookies.set('helpIsActive', JSON.stringify(newHelpIsActive));
    };

  const [verificationMethod, setVerificationMethod] = useState("file");
  const [objectHash, setObjectHash] = useState(initialHash);
  const [stampData, setStampData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(""); // new state to manage screen
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(
    djangoContext.userIsAuthenticated,
  );
  const [button, setButton] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const regex = /^(0x)?[0-9a-fA-F]{64}$/;
  let timeout = null;
  let timeoutDuration = 10 * 60 * 1000; //1 minute
  let lastTimeoutInvocation = Date.now();
  const onMouseMove = () => {
    if (!isAuthenticated) {
      return;
    }
    restartAutoReset(timeoutDuration);
  };
  const restartAutoReset = (setTimeoutDuration) => {
    // Check if the timeout has already occurred
    let isTimedOut =
      Date.now() > new Date(lastTimeoutInvocation + timeoutDuration);

    if (isTimedOut) {
      lastTimeoutInvocation = Date.now();
      pingForwarder(isAuthenticated);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        pingForwarder(isAuthenticated);
        clearTimeout(timeout);
      }, 0);
    }
  };

  const handleVerificationMethodChange = (method) => {
    if (method !== verificationMethod) {
      setCurrentScreen("");
      setObjectHash("");
      setIsValid(true);
      setButton(false);
    }
    setVerificationMethod(method);
  };

  const handleDefaultScreen = (event) => {
    setButton(false);
    setError(false);
    setCurrentScreen("");
    setObjectHash("");
    setLoading(false);
  };

  const handleFileInput = (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      const file = event.target.files?.[0] || event.dataTransfer?.files?.[0];
      setButton(false);
      if (file) {
        setStampData(null);
        setObjectHash("");
        handleProcessFile(file);
      }
    }
  };

  const handleHashChange = (event) => {
    const value = event.target.value;
    if (isAuthenticated) {
      const isValid = regex.test(value);
      setIsValid(isValid || value === "");
      setObjectHash(value);
      setButton(false);
      if (!isLoading && value !== "" && isValid) {
        handleDebouncedUpdateHashWorker(value);
      }
      {
        setCurrentScreen("");
      }
    }
  };
  const handleDebouncedUpdateHashWorker = useCallback(
    debounce((value) => handleUpdateHashWorker(value), 2000),
    [handleUpdateHashWorker],
  );
  const handleRemoveHash = () => {
    setCurrentScreen("");
    setObjectHash("");
    setButton(false);
    setIsValid(true);
  };
  const handleCreateSubmit = () => {
    // fetch /stamp/create-stamp/
    createSubmit(objectHash, setLoading, setError, handleUpdateHashWorker);
  };

  const handleProcessFile = (file) => {
    // get hash with web3
    processFile(file, setObjectHash, handleUpdateHashWorker);
  };
  const handleUpdateHashWorker = (hash, created = false) => {
    // fetch /verify/hash/
    updateHashWorker(
      hash,
      created,
      setIsLoading,
      setError,
      setStampData,
      setCurrentScreen,
      setButton,
      setLoading,
    );
  };

  const buttonStampClassName = `rb-btn rb-btn-click rb-btn-stamp rb-display-flex rb-align-items-center ${
    button ? "" : "rb-btn-disabled"
  }`;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 639);
  const isDisabled = !objectHash || (objectHash && currentScreen === "found");

  const handlePingForwarder = useCallback(() => {
    pingForwarder(isAuthenticated);
  }, [isAuthenticated]);
  useEffect(() => {
    handlePingForwarder();
    window.removeEventListener("mousemove", onMouseMove);
    window.addEventListener("mousemove", onMouseMove);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 639);
    };
    restartAutoReset(0);
    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [handlePingForwarder]);


  const [isOpenNotification, setIsOpenNotification] = useState(true);

  const toggleNotification = () => {
    setIsOpenNotification(!isOpenNotification);
  };


  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const remainingScroll = documentHeight - (scrollTop + windowHeight);

    if ((remainingScroll <= 80 && window.innerWidth <= 639) || (remainingScroll <= 48 && window.innerWidth >= 640)) {
      setIsNearBottom(true);
    } else {
      setIsNearBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const rbRoot = document.querySelector('.rb-root');
    if (currentScreen === "created" || error) {
      rbRoot.classList.remove("full-screen-min-height");
    }

    return () => {
      rbRoot.classList.remove("full-screen-min-height");
    };
  }, [currentScreen, error]);
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext}/>
      <div className="rb-main-content">
        {error ? (
          <StampError
            error={error}
            staticBasePath={staticBasePath}
            handleDefaultScreen={handleDefaultScreen}
          />
        ) : currentScreen === "created" ? (
          <StampCreated
            data={stampData}
            staticBasePath={staticBasePath}
            handleDefaultScreen={handleDefaultScreen}
          />
        ) : (
          <>
            <div className="rb-screen">
              {loading && <Loading/>}

              <div className="rb-header-wrap">
              <p className="rb-header rb-text-center rb-mt-xl">
                Make a <b>vBase</b> Stamp
              </p>
              <p className="rb-subheader rb-text-center">
                A verifiable tamperproof timestamp for your data
              </p>
                <NeedHelp staticBasePath={staticBasePath} linkDocs="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper" />
              </div>
              <div className="rb-method-wrapper">
                <div
                  id="verify-method-chooser"
                  className="rb-btns-select rb-mt-normal"
                  data-method={verificationMethod}
                >
                  <button
                    id="verify-method-file"
                    className={`rb-btn rb-btn-file ${
                      verificationMethod === "file" ? "rb-btn-selected" : ""
                    }`}
                    onClick={() => handleVerificationMethodChange("file")}
                  >
                    <IconStampFromFile/>
                    From File
                  </button>
                  <button
                    id="verify-method-hash"
                    className={`rb-btn rb-btn-hash ${
                      verificationMethod === "hash" ? "rb-btn-selected" : ""
                    }`}
                    onClick={() => handleVerificationMethodChange("hash")}
                  >
                    <IconStampContentId/>
                    Content ID
                  </button>
                  {helpIsActive && (
                  <Help position="right" text="Choose whether to calculate a digital fingerprint from a local file, or input a digital fingerprint/content ID directly (advanced)." link="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper" />
                  )}
                </div>
              </div>

              {verificationMethod === "hash" && (
                <StampContentId
                  currentScreen={currentScreen}
                  staticBasePath={staticBasePath}
                  isValid={isValid}
                  objectHash={objectHash}
                  handleHashChange={handleHashChange}
                  handleRemoveHash={handleRemoveHash}
                  handleCreateSubmit={handleCreateSubmit}
                  isDisabled={isDisabled}
                  isSmallScreen={isSmallScreen}
                  isAuthenticated={isAuthenticated}
                  buttonStampClassName={buttonStampClassName}
                />
              )}

              {verificationMethod === "file" && (
                <StampFromFile
                  currentScreen={currentScreen}
                  staticBasePath={staticBasePath}
                  objectHash={objectHash}
                  handleFileInput={handleFileInput}
                  isAuthenticated={isAuthenticated}
                  isDisabled={isDisabled}
                  helpIsActive={helpIsActive}
                />
              )}

              {(!isSmallScreen || verificationMethod === "file") && (
                <div

                  className="rb-info rb-flex-wrap-wrap">
                  <button
                    className={buttonStampClassName}
                    onClick={handleCreateSubmit}
                    disabled={!button}
                  >
                    Make a vBase Stamp!
                  </button>

                  {!isAuthenticated && (
                    <div className="rb-stamper-login rb-display-flex rb-justify-content-center w-100">
                      <a href="/accounts/login/">Sign In</a> to Start Stamping!
                    </div>
                  )}
                  {helpIsActive && (
                    <Help position="right" text="Once you click “Make a vBase Stamp” the digital fingerprint of your data will be included in a blockchain transaction, creating a trusted verifiable timestamp!" link="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper" />
                  )}
                </div>
              )}
              <div className="stamp-bulk__text">
                Need to stamp in bulk?{" "}
                <a
                  href="https://docs.vbase.com/getting-started/python-quickstart"
                  target="_blank"
                >
                  Check out our handy API
                </a>
              </div>
            </div>
            <TriggerHelp helpIsActive={helpIsActive} isNearBottom={isNearBottom} isAuthenticated={isAuthenticated}
                         isOpenNotification={isOpenNotification} handleHelpTriggerClick={handleHelpTriggerClick}/>
          </>
        )}
      </div>

      <Footer/>
      {!isAuthenticated &&
        <Notification isOpenNotification={isOpenNotification} toggleNotification={toggleNotification}/>}
    </>
  );
};

export default StampForm;
